.App {
  text-align: center;
}

.modal{
  background-color: hsl(0 0% 0% / 50%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ms-5 {
  margin-left: 0 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dropdown-item.active {
  background-color: #da291c !important;
  color: #fff;
  text-decoration: none;
}
.form-control {
  border: 0;
  border-radius: 0;
}
.form-control-not-required {
  border-bottom: 1px #a1a1a1 solid !important;
}

.form-control-required {
  border-bottom: 1px #dc3545 solid !important;
}
.form-control {
  appearance: none;
  background-clip: padding-box;
  background-color: transparent;
  border: 1px #a1a1a1 solid;
  border-radius: 0.25rem;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
.form-control:focus,
.form-select:focus {
  background-color: red;
  border-color: red;
  box-shadow: 0 0 0 0 transparent;
  color: #212529;
  outline: 0;
}

.form-control,
.form-select {
  border-radius: 0;
}
.form-control:focus,
.form-select:focus {
  background-color: red;
  border-color: inherit;
  box-shadow: 0 0 0 0 transparent;
  color: #212529;
  outline: 0;
}
.form-floating {
  width: 100%;
}

.form-floating > label {
  top: 2px;
  color: #0f0e0e;
  font-size: 16px;
  font-family: Inter;
  font-weight: 300;
  line-height: 1;
  word-wrap: break-word;
  padding-left: 24px;
}
a:focus,
btn:focus {
  outline: none;
}
.form-floating > .form-control:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border-bottom: 1px #a1a1a1 solid !important;
}

.form-floating > .form-control {
  outline: none;
  box-shadow: none;
  border-radius: 0;
}
input[type="text"]:focus {
  outline: none;
  border: 0 !important;
  outline: 0 !important;
}

input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  border-color: transparent;
  padding-left: 0;
  border: 0;
}
.form-floating > .form-control:focus + .label {
  outline: none;
  box-shadow: none;
  border-bottom: 1px #a1a1a1 solid !important;
  background-color: transparent;
}
.form-floating > .form-select:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 1px #a1a1a1 solid !important;
  background-color: transparent;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  bottom: 10px;
  content: "";
  background-color: transparent !important;
  border-radius: 0;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  left: 10px !important;
  top: 10px !important;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  border: 0;
  background-color: transparent;
  /* border-bottom: 1px #a1a1a1 solid !important; */
  border-radius: 0;
}

.fullWidth {
  width: 100%;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
}
/* animation */
input:focus {
  outline: none;
}

.elq-form .elq-required {
  color: red !important;
  display: inline;
  float: none;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.elq-form #elq-FormLastRow {
  position: absolute;
  left: -80%;
  top: -80%;
}
.elq-form .elq-label {
  display: inline-block;
  max-width: 100%;
  font-weight: 400;
  box-sizing: border-box;
  margin-bottom: 0;
  font-size: 12px;
}
.elq-form .elq-label-top {
  padding-bottom: 3px;
}
.elq-form .elq-item-label {
  max-width: 100%;
  font-weight: 400;
  box-sizing: border-box;
}
.elq-form .list-order {
  margin-left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.elq-form .one-column {
  width: 100%;
  clear: both;
}
.elq-form .two-column {
  float: left;
  width: 38%;
}
.elq-form .three-column {
  float: left;
  width: 30%;
}
.elq-form .elq-item-textarea {
  resize: vertical;
  min-height: 48px;
  max-height: 360px;
  box-sizing: border-box;
}
.elq-form .elq-item-input,
.elq-form .elq-item-select,
.elq-form .elq-item-textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font: inherit;
  margin: 0;
  background-color: transparent;
  border-bottom: 1px #a1a1a1 solid !important;
  font-size: 16px;
}
.elq-form .elq-item-input:focus,
.elq-form .elq-item-select:focus,
.elq-form .elq-item-textarea:focus {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font: inherit;
  margin: 0;
  background-color: transparent;
  border-bottom: 1px #a1a1a1 solid !important;
}
.elq-form .elq-item-select {
  text-transform: none;
}
.elq-form .row.single-checkbox-row {
  margin-left: 0;
}
.elq-form .elq-field-style {
  padding-bottom: 10px;
}
.elq-form .elq-field-style input[type="submit"] {
  -webkit-appearance: square-button;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.elq-form .loader {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.elq-form input[type="checkbox"],
.elq-form input[type="radio"] {
  margin: 0;
}
.elq-form .list-order input[type="radio"] {
  margin-left: 1px;
}
.elq-form *,
.elq-form:after,
.elq-form:before {
  box-sizing: border-box;
}
.elq-form html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
.elq-form body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333;
  background-color: #fff;
}
.elq-form button,
.elq-form input,
.elq-form select,
.elq-form textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elq-form a {
  color: #337ab7;
  text-decoration: none;
}
.elq-form a:focus,
.elq-form a:hover {
  color: #23527c;
  text-decoration: underline;
}
.elq-form a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.elq-form figure {
  margin: 0;
}
.elq-form img {
  vertical-align: middle;
}
.elq-form .img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.elq-form .img-rounded {
  border-radius: 6px;
}
.elq-form .img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.elq-form .img-circle {
  border-radius: 50%;
}
.elq-form hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.elq-form .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.elq-form .sr-only-focusable:active,
.elq-form .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.elq-form [role="button"] {
  cursor: pointer;
}
.elq-form .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.elq-form .container:after,
.elq-form .container:before {
  display: table;
  content: " ";
}
.elq-form .container:after {
  clear: both;
}
@media (min-width: 768px) {
  .elq-form .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .elq-form .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .elq-form .container {
    width: 1170px;
  }
}
.elq-form .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.elq-form .container-fluid:after,
.elq-form .container-fluid:before {
  display: table;
  content: " ";
}
.elq-form .container-fluid:after {
  clear: both;
}
.elq-form .row {
  margin-right: -15px;
  margin-left: -15px;
}
.elq-form .row:after,
.elq-form .row:before {
  display: table;
  content: " ";
}
.elq-form .row:after {
  clear: both;
}
.elq-form .row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.elq-form .row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.elq-form .col-lg-1,
.elq-form .col-lg-10,
.elq-form .col-lg-11,
.elq-form .col-lg-12,
.elq-form .col-lg-2,
.elq-form .col-lg-3,
.elq-form .col-lg-4,
.elq-form .col-lg-5,
.elq-form .col-lg-6,
.elq-form .col-lg-7,
.elq-form .col-lg-8,
.elq-form .col-lg-9,
.elq-form .col-md-1,
.elq-form .col-md-10,
.elq-form .col-md-11,
.elq-form .col-md-12,
.elq-form .col-md-2,
.elq-form .col-md-3,
.elq-form .col-md-4,
.elq-form .col-md-5,
.elq-form .col-md-6,
.elq-form .col-md-7,
.elq-form .col-md-8,
.elq-form .col-md-9,
.elq-form .col-sm-1,
.elq-form .col-sm-10,
.elq-form .col-sm-11,
.elq-form .col-sm-12,
.elq-form .col-sm-2,
.elq-form .col-sm-3,
.elq-form .col-sm-4,
.elq-form .col-sm-5,
.elq-form .col-sm-6,
.elq-form .col-sm-7,
.elq-form .col-sm-8,
.elq-form .col-sm-9,
.elq-form .col-xs-1,
.elq-form .col-xs-10,
.elq-form .col-xs-11,
.elq-form .col-xs-12,
.elq-form .col-xs-2,
.elq-form .col-xs-3,
.elq-form .col-xs-4,
.elq-form .col-xs-5,
.elq-form .col-xs-6,
.elq-form .col-xs-7,
.elq-form .col-xs-8,
.elq-form .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.elq-form .col-xs-1,
.elq-form .col-xs-10,
.elq-form .col-xs-11,
.elq-form .col-xs-12,
.elq-form .col-xs-2,
.elq-form .col-xs-3,
.elq-form .col-xs-4,
.elq-form .col-xs-5,
.elq-form .col-xs-6,
.elq-form .col-xs-7,
.elq-form .col-xs-8,
.elq-form .col-xs-9 {
  float: left;
}
.elq-form .col-xs-1 {
  width: 8.33333%;
}
.elq-form .col-xs-2 {
  width: 16.66667%;
}
.elq-form .col-xs-3 {
  width: 25%;
}
.elq-form .col-xs-4 {
  width: 33.33333%;
}
.elq-form .col-xs-5 {
  width: 41.66667%;
}
.elq-form .col-xs-6 {
  width: 50%;
}
.elq-form .col-xs-7 {
  width: 58.33333%;
}
.elq-form .col-xs-8 {
  width: 66.66667%;
}
.elq-form .col-xs-9 {
  width: 75%;
}
.elq-form .col-xs-10 {
  width: 83.33333%;
}
.elq-form .col-xs-11 {
  width: 91.66667%;
}
.elq-form .col-xs-12 {
  width: 100%;
}
.elq-form .col-xs-pull-0 {
  right: auto;
}
.elq-form .col-xs-pull-1 {
  right: 8.33333%;
}
.elq-form .col-xs-pull-2 {
  right: 16.66667%;
}
.elq-form .col-xs-pull-3 {
  right: 25%;
}
.elq-form .col-xs-pull-4 {
  right: 33.33333%;
}
.elq-form .col-xs-pull-5 {
  right: 41.66667%;
}
.elq-form .col-xs-pull-6 {
  right: 50%;
}
.elq-form .col-xs-pull-7 {
  right: 58.33333%;
}
.elq-form .col-xs-pull-8 {
  right: 66.66667%;
}
.elq-form .col-xs-pull-9 {
  right: 75%;
}
.elq-form .col-xs-pull-10 {
  right: 83.33333%;
}
.elq-form .col-xs-pull-11 {
  right: 91.66667%;
}
.elq-form .col-xs-pull-12 {
  right: 100%;
}
.elq-form .col-xs-push-0 {
  left: auto;
}
.elq-form .col-xs-push-1 {
  left: 8.33333%;
}
.elq-form .col-xs-push-2 {
  left: 16.66667%;
}
.elq-form .col-xs-push-3 {
  left: 25%;
}
.elq-form .col-xs-push-4 {
  left: 33.33333%;
}
.elq-form .col-xs-push-5 {
  left: 41.66667%;
}
.elq-form .col-xs-push-6 {
  left: 50%;
}
.elq-form .col-xs-push-7 {
  left: 58.33333%;
}
.elq-form .col-xs-push-8 {
  left: 66.66667%;
}
.elq-form .col-xs-push-9 {
  left: 75%;
}
.elq-form .col-xs-push-10 {
  left: 83.33333%;
}
.elq-form .col-xs-push-11 {
  left: 91.66667%;
}
.elq-form .col-xs-push-12 {
  left: 100%;
}
.elq-form .col-xs-offset-0 {
  margin-left: 0;
}
.elq-form .col-xs-offset-1 {
  margin-left: 8.33333%;
}
.elq-form .col-xs-offset-2 {
  margin-left: 16.66667%;
}
.elq-form .col-xs-offset-3 {
  margin-left: 25%;
}
.elq-form .col-xs-offset-4 {
  margin-left: 33.33333%;
}
.elq-form .col-xs-offset-5 {
  margin-left: 41.66667%;
}
.elq-form .col-xs-offset-6 {
  margin-left: 50%;
}
.elq-form .col-xs-offset-7 {
  margin-left: 58.33333%;
}
.elq-form .col-xs-offset-8 {
  margin-left: 66.66667%;
}
.elq-form .col-xs-offset-9 {
  margin-left: 75%;
}
.elq-form .col-xs-offset-10 {
  margin-left: 83.33333%;
}
.elq-form .col-xs-offset-11 {
  margin-left: 91.66667%;
}
.elq-form .col-xs-offset-12 {
  margin-left: 100%;
}
@media (min-width: 430px) {
  .elq-form .col-sm-1,
  .elq-form .col-sm-10,
  .elq-form .col-sm-11,
  .elq-form .col-sm-12,
  .elq-form .col-sm-2,
  .elq-form .col-sm-3,
  .elq-form .col-sm-4,
  .elq-form .col-sm-5,
  .elq-form .col-sm-6,
  .elq-form .col-sm-7,
  .elq-form .col-sm-8,
  .elq-form .col-sm-9 {
    float: left;
  }
  .elq-form .col-sm-1 {
    width: 8.33333%;
  }
  .elq-form .col-sm-2 {
    width: 16.66667%;
  }
  .elq-form .col-sm-3 {
    width: 25%;
  }
  .elq-form .col-sm-4 {
    width: 33.33333%;
  }
  .elq-form .col-sm-5 {
    width: 41.66667%;
  }
  .elq-form .col-sm-6 {
    width: 50%;
  }
  .elq-form .col-sm-7 {
    width: 58.33333%;
  }
  .elq-form .col-sm-8 {
    width: 66.66667%;
  }
  .elq-form .col-sm-9 {
    width: 75%;
  }
  .elq-form .col-sm-10 {
    width: 83.33333%;
  }
  .elq-form .col-sm-11 {
    width: 91.66667%;
  }
  .elq-form .col-sm-12 {
    width: 100%;
  }
  .elq-form .col-sm-pull-0 {
    right: auto;
  }
  .elq-form .col-sm-pull-1 {
    right: 8.33333%;
  }
  .elq-form .col-sm-pull-2 {
    right: 16.66667%;
  }
  .elq-form .col-sm-pull-3 {
    right: 25%;
  }
  .elq-form .col-sm-pull-4 {
    right: 33.33333%;
  }
  .elq-form .col-sm-pull-5 {
    right: 41.66667%;
  }
  .elq-form .col-sm-pull-6 {
    right: 50%;
  }
  .elq-form .col-sm-pull-7 {
    right: 58.33333%;
  }
  .elq-form .col-sm-pull-8 {
    right: 66.66667%;
  }
  .elq-form .col-sm-pull-9 {
    right: 75%;
  }
  .elq-form .col-sm-pull-10 {
    right: 83.33333%;
  }
  .elq-form .col-sm-pull-11 {
    right: 91.66667%;
  }
  .elq-form .col-sm-pull-12 {
    right: 100%;
  }
  .elq-form .col-sm-push-0 {
    left: auto;
  }
  .elq-form .col-sm-push-1 {
    left: 8.33333%;
  }
  .elq-form .col-sm-push-2 {
    left: 16.66667%;
  }
  .elq-form .col-sm-push-3 {
    left: 25%;
  }
  .elq-form .col-sm-push-4 {
    left: 33.33333%;
  }
  .elq-form .col-sm-push-5 {
    left: 41.66667%;
  }
  .elq-form .col-sm-push-6 {
    left: 50%;
  }
  .elq-form .col-sm-push-7 {
    left: 58.33333%;
  }
  .elq-form .col-sm-push-8 {
    left: 66.66667%;
  }
  .elq-form .col-sm-push-9 {
    left: 75%;
  }
  .elq-form .col-sm-push-10 {
    left: 83.33333%;
  }
  .elq-form .col-sm-push-11 {
    left: 91.66667%;
  }
  .elq-form .col-sm-push-12 {
    left: 100%;
  }
  .elq-form .col-sm-offset-0 {
    margin-left: 0;
  }
  .elq-form .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .elq-form .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .elq-form .col-sm-offset-3 {
    margin-left: 25%;
  }
  .elq-form .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .elq-form .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .elq-form .col-sm-offset-6 {
    margin-left: 50%;
  }
  .elq-form .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .elq-form .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .elq-form .col-sm-offset-9 {
    margin-left: 75%;
  }
  .elq-form .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .elq-form .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .elq-form .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .elq-form .col-md-1,
  .elq-form .col-md-10,
  .elq-form .col-md-11,
  .elq-form .col-md-12,
  .elq-form .col-md-2,
  .elq-form .col-md-3,
  .elq-form .col-md-4,
  .elq-form .col-md-5,
  .elq-form .col-md-6,
  .elq-form .col-md-7,
  .elq-form .col-md-8,
  .elq-form .col-md-9 {
    float: left;
  }
  .elq-form .col-md-1 {
    width: 8.33333%;
  }
  .elq-form .col-md-2 {
    width: 16.66667%;
  }
  .elq-form .col-md-3 {
    width: 25%;
  }
  .elq-form .col-md-4 {
    width: 33.33333%;
  }
  .elq-form .col-md-5 {
    width: 41.66667%;
  }
  .elq-form .col-md-6 {
    width: 50%;
  }
  .elq-form .col-md-7 {
    width: 58.33333%;
  }
  .elq-form .col-md-8 {
    width: 66.66667%;
  }
  .elq-form .col-md-9 {
    width: 75%;
  }
  .elq-form .col-md-10 {
    width: 83.33333%;
  }
  .elq-form .col-md-11 {
    width: 91.66667%;
  }
  .elq-form .col-md-12 {
    width: 100%;
  }
  .elq-form .col-md-pull-0 {
    right: auto;
  }
  .elq-form .col-md-pull-1 {
    right: 8.33333%;
  }
  .elq-form .col-md-pull-2 {
    right: 16.66667%;
  }
  .elq-form .col-md-pull-3 {
    right: 25%;
  }
  .elq-form .col-md-pull-4 {
    right: 33.33333%;
  }
  .elq-form .col-md-pull-5 {
    right: 41.66667%;
  }
  .elq-form .col-md-pull-6 {
    right: 50%;
  }
  .elq-form .col-md-pull-7 {
    right: 58.33333%;
  }
  .elq-form .col-md-pull-8 {
    right: 66.66667%;
  }
  .elq-form .col-md-pull-9 {
    right: 75%;
  }
  .elq-form .col-md-pull-10 {
    right: 83.33333%;
  }
  .elq-form .col-md-pull-11 {
    right: 91.66667%;
  }
  .elq-form .col-md-pull-12 {
    right: 100%;
  }
  .elq-form .col-md-push-0 {
    left: auto;
  }
  .elq-form .col-md-push-1 {
    left: 8.33333%;
  }
  .elq-form .col-md-push-2 {
    left: 16.66667%;
  }
  .elq-form .col-md-push-3 {
    left: 25%;
  }
  .elq-form .col-md-push-4 {
    left: 33.33333%;
  }
  .elq-form .col-md-push-5 {
    left: 41.66667%;
  }
  .elq-form .col-md-push-6 {
    left: 50%;
  }
  .elq-form .col-md-push-7 {
    left: 58.33333%;
  }
  .elq-form .col-md-push-8 {
    left: 66.66667%;
  }
  .elq-form .col-md-push-9 {
    left: 75%;
  }
  .elq-form .col-md-push-10 {
    left: 83.33333%;
  }
  .elq-form .col-md-push-11 {
    left: 91.66667%;
  }
  .elq-form .col-md-push-12 {
    left: 100%;
  }
  .elq-form .col-md-offset-0 {
    margin-left: 0;
  }
  .elq-form .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .elq-form .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .elq-form .col-md-offset-3 {
    margin-left: 25%;
  }
  .elq-form .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .elq-form .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .elq-form .col-md-offset-6 {
    margin-left: 50%;
  }
  .elq-form .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .elq-form .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .elq-form .col-md-offset-9 {
    margin-left: 75%;
  }
  .elq-form .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .elq-form .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .elq-form .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .elq-form .col-lg-1,
  .elq-form .col-lg-10,
  .elq-form .col-lg-11,
  .elq-form .col-lg-12,
  .elq-form .col-lg-2,
  .elq-form .col-lg-3,
  .elq-form .col-lg-4,
  .elq-form .col-lg-5,
  .elq-form .col-lg-6,
  .elq-form .col-lg-7,
  .elq-form .col-lg-8,
  .elq-form .col-lg-9 {
    float: left;
  }
  .elq-form .col-lg-1 {
    width: 8.33333%;
  }
  .elq-form .col-lg-2 {
    width: 16.66667%;
  }
  .elq-form .col-lg-3 {
    width: 25%;
  }
  .elq-form .col-lg-4 {
    width: 33.33333%;
  }
  .elq-form .col-lg-5 {
    width: 41.66667%;
  }
  .elq-form .col-lg-6 {
    width: 50%;
  }
  .elq-form .col-lg-7 {
    width: 58.33333%;
  }
  .elq-form .col-lg-8 {
    width: 66.66667%;
  }
  .elq-form .col-lg-9 {
    width: 75%;
  }
  .elq-form .col-lg-10 {
    width: 83.33333%;
  }
  .elq-form .col-lg-11 {
    width: 91.66667%;
  }
  .elq-form .col-lg-12 {
    width: 100%;
  }
  .elq-form .col-lg-pull-0 {
    right: auto;
  }
  .elq-form .col-lg-pull-1 {
    right: 8.33333%;
  }
  .elq-form .col-lg-pull-2 {
    right: 16.66667%;
  }
  .elq-form .col-lg-pull-3 {
    right: 25%;
  }
  .elq-form .col-lg-pull-4 {
    right: 33.33333%;
  }
  .elq-form .col-lg-pull-5 {
    right: 41.66667%;
  }
  .elq-form .col-lg-pull-6 {
    right: 50%;
  }
  .elq-form .col-lg-pull-7 {
    right: 58.33333%;
  }
  .elq-form .col-lg-pull-8 {
    right: 66.66667%;
  }
  .elq-form .col-lg-pull-9 {
    right: 75%;
  }
  .elq-form .col-lg-pull-10 {
    right: 83.33333%;
  }
  .elq-form .col-lg-pull-11 {
    right: 91.66667%;
  }
  .elq-form .col-lg-pull-12 {
    right: 100%;
  }
  .elq-form .col-lg-push-0 {
    left: auto;
  }
  .elq-form .col-lg-push-1 {
    left: 8.33333%;
  }
  .elq-form .col-lg-push-2 {
    left: 16.66667%;
  }
  .elq-form .col-lg-push-3 {
    left: 25%;
  }
  .elq-form .col-lg-push-4 {
    left: 33.33333%;
  }
  .elq-form .col-lg-push-5 {
    left: 41.66667%;
  }
  .elq-form .col-lg-push-6 {
    left: 50%;
  }
  .elq-form .col-lg-push-7 {
    left: 58.33333%;
  }
  .elq-form .col-lg-push-8 {
    left: 66.66667%;
  }
  .elq-form .col-lg-push-9 {
    left: 75%;
  }
  .elq-form .col-lg-push-10 {
    left: 83.33333%;
  }
  .elq-form .col-lg-push-11 {
    left: 91.66667%;
  }
  .elq-form .col-lg-push-12 {
    left: 100%;
  }
  .elq-form .col-lg-offset-0 {
    margin-left: 0;
  }
  .elq-form .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .elq-form .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .elq-form .col-lg-offset-3 {
    margin-left: 25%;
  }
  .elq-form .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .elq-form .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .elq-form .col-lg-offset-6 {
    margin-left: 50%;
  }
  .elq-form .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .elq-form .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .elq-form .col-lg-offset-9 {
    margin-left: 75%;
  }
  .elq-form .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .elq-form .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .elq-form .col-lg-offset-12 {
    margin-left: 100%;
  }
}
.elq-form .row {
  display: inherit;
}
.LV_invalid_field,
input.LV_invalid_field:active,
input.LV_invalid_field:hover,
textarea.LV_invalid_field:active,
textarea.LV_invalid_field:hover {
  border-bottom: 1px solid #c00 !important;
}
.LV_invalid_field {
  border-bottom: 1px solid #c00 !important;
}
.LV_validation_message {
  font-weight: 700;
  margin: 0 0 0 5px;
}
.LV_valid {
  display: none;
}
.LV_invalid {
  color: #c00;
  font-size: 10px;
}
.submit-button-style {
  padding: 7px 20px;
  border: 1px solid #979797;
  border-radius: 3px;
  background-color: #fff;
  color: #4a4a4a;
  cursor: pointer;
  height: 48px;
}
.checkbox-aligned {
  margin-left: 5px;
  display: inline;
}
.form-element-layout {
  padding: 5px 10px;
}
.form-element-instruction {
  font-size: 10px;
}
.form-element-form-text {
  margin: 0;
}
.form-field-visible-xs-block {
  display: none;
}
.elq-form-design-field .list-order input[type="radio"] {
  margin-left: 1px;
}
@media (max-width: 767px) {
  .form-field-visible-xs-block {
    display: block;
  }
}
.form-field-hidden-xs {
  display: block;
}
@media (max-width: 767px) {
  .form-field-hidden-xs {
    display: none;
  }
}
.custom-overlay {
  margin-left: 2px;
  background: #fff;
  box-shadow: 0 0 4px 0 #777;
  border-radius: 2px;
  max-width: 256px;
}

.fullpage_background_color {
  /* width:"100%";
  height:"24px";
  text-align:"center";
  position:"absolute";
  top:"20%";
  margin-top:"20"; */
  /* background: linear-gradient(
    269deg,
    #da291c 12.65%,
    rgba(218, 41, 28, 0) 113.72%
  ); */

  /* background: linear-gradient(
    260deg,
    #da291c 0.65%,
    rgba(218, 41, 28, 0) 50.72%
  ); */
  background-image: url("assets/images/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left 300px bottom -80px;
}
.error_404 {
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  color: black;
  width: "100%";
  height: "24px";
  text-align: "center";
  position: "absolute";
  top: "20";
  margin-top: "20";
}
.Page_notfound {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.72px;
  color: black;
}
.goToHomepage {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #da291c;
}

.columnThank {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.thankTextOne {
  color: #0f0e0e;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}
.thankTextTwo {
  color: #464646;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 24px;
}

.buttonHomeThank {
  padding: 12px 24px;
  border: 1px #da291c solid;
  width: 237px;
  color: #da291c;
  display: flex;
  cursor: pointer;
  background: #da291c;
  color: white !important;
}

.bluebox {
  animation-name: my-blue;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 95px;
  height: 235px;
  background: #307fe2;
  position: absolute;
  left: 51%;
  top: 30%;
}

@keyframes my-blue {
  from {
    background-color: #307fe2;
    width: 95px;
    top: 0%;
  }
  to {
    background-color: #307fe2;
    width: 95px;
    top: 30%;
  }
}
.blueboxTwo {
  animation-name: my-blue-two;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 95px;
  height: 36px;
  opacity: 0.6;
  background: #307fe2;
  position: absolute;
  left: 51%;
  top: 70%;
}
@keyframes my-blue-two {
  from {
    opacity: 0.6;
    background: #307fe2;
    width: 95px;
    top: 0%;
  }
  to {
    opacity: 0.6;
    background: #307fe2;
    width: 95px;
    top: 70%;
  }
}

.redbox {
  animation-name: my-red;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 176px;
  height: 36px;
  background: #da291c;
  position: absolute;
  left: 55%;
  top: 0;
}

@keyframes my-red {
  from {
    background: #da291c;
    width: 0px;
    left: 55%;
  }
  to {
    background: #da291c;
    width: 95px;
    left: 55%;
  }
}
.lightbox {
  animation-name: my-lightbox;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 114px;
  height: 170px;
  border-radius: 60px 0 0 0;
  background: #2cccd3;
  position: absolute;
  right: 8%;
  top: 20%;
}

@keyframes my-lightbox {
  from {
    background: #2cccd3;
    height: 0px;
    right: 8%;
  }
  to {
    background: #2cccd3;
    height: 170px;
    right: 8%;
  }
}
.lightTwobox {
  animation-name: my-lightbox-two;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 114px;
  height: 34px;
  opacity: 0.6;
  background: #2cccd3;
  position: absolute;
  right: 8%;
  top: 48%;
}
@keyframes my-lightbox-two {
  from {
    opacity: 0.6;
    background: #2cccd3;
    height: 0px;
    right: 8%;
  }
  to {
    opacity: 0.6;
    background: #2cccd3;
    height: 34px;
    right: 8%;
  }
}

.redTwo {
  animation-name: my-red-two;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-timing-function: linear;
  width: 402px;
  height: 70px;
  flex-shrink: 0;
  background: #da291c;
  position: absolute;
  right: 8%;
  bottom: 5%;
}
@keyframes my-red-two {
  from {
    background: #da291c;
    height: 0px;
    bottom: 5%;
  }
  to {
    background: #da291c;
    height: 70px;
    bottom: 5%;
  }
}

.imageTag {
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(
      0deg,
      rgba(15, 14, 14, 0.2) 0%,
      rgba(15, 14, 14, 0.2) 100%
    ),
    url("assets/images/thank.png"), lightgray 50% / cover no-repeat;
}

.dot {
  width: 200px;
  height: 200px;
  background-image: url("assets/images/dot.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 30%;
  top: 10%;
}

.other {
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.12px;
}

